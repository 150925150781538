import React from "react"
import { navigate } from "gatsby"
import { AppProvider, Page } from "@shopify/polaris"

import { Declined } from "components/Declined/Declined"

const DeclinedPage = () => {
  return (
    <AppProvider>
      <Page title={`Charge Declined`}>
        <Declined
          onClick={() =>
            navigate(`/install`, {
              replace: true,
            })
          }
        />
      </Page>
    </AppProvider>
  )
}

export default DeclinedPage
