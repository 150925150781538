import React from "react"
import { Layout, Banner } from "@shopify/polaris"

export const Declined = props => {
  const { onClick } = props

  return (
    <Layout>
      <Layout.Section>
        <Banner
          title="There was an error installing app."
          status="critical"
          action={{
            content: "Re-Install",
            onAction: () => onClick,
          }}
        >
          Error: Unable to activate application charge.
        </Banner>
      </Layout.Section>
    </Layout>
  )
}
